.ant-checkbox {
  .ant-checkbox-inner {
    @apply border-purple;
  }

  &-wrapper {
    &:hover {
      .ant-checkbox-inner {
        @apply border-purple;
      }
    }
  }

  &.cdk-mouse-focused {
    &:after {
      @apply border-purple;
    }
  }
  &-checked {
    &:after {
      @apply border-purple;
    }
    .ant-checkbox-inner {
      @apply bg-purple;
    }
  }
}
