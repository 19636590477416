.bottom-footer-links {
    // custom collapse style
    .ant-collapse {
        $root: &;
        @apply border-0;

        &-item {
            @apply bg-white border-0;

            .ant-collapse {
                &-header {
                    @apply p-0  #{!important};
                }
        
                &-content {
                    @apply border-0 transition duration-700 opacity-[0];
                    
                    &-box {
                        @apply py-0 pb-2;
                    }
                }
            }

            &-active {
                .ant-collapse {
                    &-content {
                        @apply opacity-[1];
                    }
                    &-header {
                        .arrow-icon {
                            @apply -rotate-[45deg];
                            
                            &:before {
                                @apply bg-purple;
                            }
                        }
                    }
                }
            }
        }
    }
}