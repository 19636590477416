@media (max-width: 640px) {
  .designer-profile-detail-modal {
    max-width: 100vw;
    top: 0 !important;
    margin-top: 0;
    height: 100%;
    padding: 0;
    margin-bottom: 0;

    .ant-modal-content {
      height: 100%;
    }
  }
}

@media (min-width: 678px) and (max-width: 991px) {
  .designer-profile-detail-wrapper {
    padding-bottom: 60px;
  }
}

.designer-profile-detail-modal-wrapper {
  .ant-modal-close-x {
    border-radius: 30px;
    transition: background ease-in-out 0.45s;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 7px;

    @media screen and (min-width: 1024px) {
      &:hover {
        opacity: 0.55;
      }
    }
    
    @media screen and (max-width: 1023px) {
        @apply bg-white;
        @apply text-deep-violet;
        font-size: 1.25rem;

        &:hover {
          opacity: 0.85;
        }
    }
  }
}
