.ant-image-preview-img {
  display: inline-block !important;
  @apply rounded-md;
}

.ant-image-preview-operations {
  &-operation {
    &:nth-child(4),
    &:nth-child(5) {
      display: none;
    }
  }
}
